<template>
    <div>
        <!-- 还需要一个系统配置的功能 配置全局的 logo 名称  图片-->
        <!--  系统集合配置： 默认首页 页面标题 标题点击跳转 页面logo 页面logo跳转 隐藏菜单 -->
        <el-dialog :title="titile" :visible.sync="drawer" :before-close="handleClose" :max-height="maxHeight" center width="70%">
            <div class="form-container">
                <div class="form-item-container">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                        <el-row>
                            <el-col :span="16">
                                <el-form-item label="种类" prop="category">
                                    <el-radio-group v-model="ruleForm.category" @change="handleRadioChange"
                                        :disabled="this.editSwitch || this.operationType === 3">
                                        <el-radio-button label="xun.life.alcohol">调酒</el-radio-button>
                                        <el-radio-button label="xun.life.dishes" disabled>菜</el-radio-button>
                                        <el-radio-button label="xun.life.tea" disabled>茶</el-radio-button>
                                        <!-- <el-radio-button label="xun.life.dessert" disabled>甜点</el-radio-button> -->
                                        <!-- <el-radio-button label="xun.life.bubble" disabled>奶茶</el-radio-button> -->
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                            <el-col :span="4">
                                <el-form-item label="是否收藏" prop="isCollection">
                                    <el-checkbox v-model="ruleForm.isCollection" :true-label="1"
                                        :disabled="this.operationType === 3" :false-label="2">是</el-checkbox>
                                </el-form-item>
                            </el-col>
                            <el-col :span="4">
                                <el-form-item label="是否家用" prop="isHousehold">
                                    <el-checkbox v-model="ruleForm.isHousehold" :true-label="1"
                                        :disabled="this.operationType === 3" :false-label="2">是</el-checkbox>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="类别" prop="classification">
                                    <el-select v-model="ruleForm.classification" placeholder="请选择" @change="AddSelectDept"
                                        :disabled="this.editSwitch || this.operationType === 3">
                                        <el-option v-for="item in classificationList" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="二级类型" prop="subType">
                                    <el-select v-model="ruleForm.subType" placeholder="请选择"
                                        :disabled="this.editSwitch || this.operationType === 3">
                                        <el-option v-for="item in subTypeList" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="编码" prop="code">
                                    <el-input v-model="ruleForm.code" :disabled="this.operationType === 3"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="名字" prop="name">
                                    <el-input v-model="ruleForm.name" placeholder="中文(英文)"
                                        :disabled="this.operationType === 3"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="别名" prop="alias">
                                    <el-input v-model="ruleForm.alias" :disabled="this.operationType === 3"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="标题" prop="title">
                                    <el-input v-model="ruleForm.title" :disabled="this.operationType === 3"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="口味" prop="taste">
                                    <el-input v-model="ruleForm.taste" :disabled="this.operationType === 3" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="来源" prop="sourceUrl">
                                    <el-input v-model="ruleForm.sourceUrl" :disabled="this.operationType === 3"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="物语" prop="tale">
                                    <el-input v-model="ruleForm.tale" :disabled="this.operationType === 3"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>


                        <el-row v-if="this.ruleForm.category == 'xun.life.alcohol'">
                            <el-col :span="8">
                                <el-form-item label="玻璃类型" prop="glassType">
                                    <el-input v-model="ruleForm.glassType"
                                        :disabled="this.operationType === 3"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="国际分类" prop="intType">
                                    <el-input v-model="ruleForm.intType" :disabled="this.operationType === 3" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="特殊分类" prop="intIba">
                                    <el-input v-model="ruleForm.intIba" :disabled="this.operationType === 3"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>


                        <el-row v-if="this.ruleForm.category == 'xun.life.alcohol'">
                            <el-col :span="8">
                                <el-form-item label="主要基酒" prop="baseAlcohol">
                                    <el-input v-model="ruleForm.baseAlcohol"
                                        :disabled="this.operationType === 3"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="品尝方法" prop="tasteMethod">
                                    <el-input v-model="ruleForm.tasteMethod" :disabled="this.operationType === 3" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="品尝时间" prop="tasteTimes">
                                    <el-input v-model="ruleForm.tasteTimes" :disabled="this.operationType === 3"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row v-if="this.ruleForm.category == 'xun.life.alcohol'">
                            <el-col :span="8">
                                <el-form-item label="调制手法" prop="modulationTechniques">
                                    <el-input v-model="ruleForm.modulationTechniques"
                                        :disabled="this.operationType === 3" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="系列" prop="series">
                                    <el-input v-model="ruleForm.series" :disabled="this.operationType === 3"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="酒精含量" prop="alcoholPercentage">
                                    <el-input v-model="ruleForm.alcoholPercentage" :disabled="this.operationType === 3" />
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <!-- <el-row v-if="this.ruleForm.category == 'xun.life.alcohol'">
                            <el-col :span="8">
                                <el-form-item label="生产年份" prop="productionYear">
                                    <el-input v-model="ruleForm.productionYear"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row> -->
                        <el-row v-if="this.ruleForm.category == 'xun.life.alcohol'">
                            <!-- <el-col :span="8">
                                <el-form-item label="品牌" prop="brand">
                                    <el-input v-model="ruleForm.brand"></el-input>
                                </el-form-item>
                            </el-col> -->
                            <el-col :span="8">
                                <el-form-item label="器具" prop="brewingContainer">
                                    <el-input v-model="ruleForm.brewingContainer"
                                        :disabled="this.operationType === 3"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="冰的使用" prop="iceUse">
                                    <el-input v-model="ruleForm.iceUse" :disabled="this.operationType === 3" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="装饰" prop="decoration">
                                    <el-input v-model="ruleForm.decoration" :disabled="this.operationType === 3"></el-input>
                                </el-form-item>
                            </el-col>

                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-form-item label="链接" prop="tutorialVideoLink">
                                    <el-input v-model="ruleForm.tutorialVideoLink"
                                        :disabled="this.operationType === 3"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row v-if="this.ruleForm.sourceData!='xun'">
                            <el-col :span="24">
                                <el-form-item label="操作引导" prop="strInstructions">
                                    <el-input v-model="ruleForm.strInstructions" disabled></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-form-item label="描述" prop="description">
                                    <el-input v-model="ruleForm.description"
                                        :disabled="this.operationType === 3"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-form-item label="注意事项" prop="note">
                                    <el-input v-model="ruleForm.note" :disabled="this.operationType === 3"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="故事" prop="story">
                                    <el-input type="textarea" autosize placeholder="请输入内容" v-model="ruleForm.story"
                                        :disabled="this.operationType === 3" />
                                </el-form-item>
                            </el-col>
                            <!-- <el-col :span="12" >
                                <el-form-item label="图片" prop="images" >
                                    <el-upload  class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/"
                                        :file-list="fileList" list-type="picture" disabled>
                                        <el-button size="small" type="primary"  >点击上传</el-button>
                                        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                                    </el-upload>
                                </el-form-item>
                            </el-col> -->
                        </el-row>
                        <el-row>
                            <el-form-item label="配置">
                                <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
                                    <el-tab-pane label="基本信息" name="infoActive">
                                        <el-row>
                                            <el-col :span="8">
                                                <el-form-item label="推荐级别" prop="recommendationLevel">
                                                    <el-rate v-model="ruleForm.recommendationLevel" 
                                                        :disabled="this.operationType === 3">
                                                    </el-rate>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="温度" prop="temperature">
                                                    <el-input v-model="ruleForm.temperature"
                                                        :disabled="this.operationType === 3" />
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item label="难度" prop="difficulty">
                                                    <el-input v-model="ruleForm.difficulty"
                                                        :disabled="this.operationType === 3" />
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="8">
                                                <el-form-item label="标准份量" prop="standardServingSize">
                                                    <el-input v-model="ruleForm.standardServingSize"
                                                        :disabled="this.operationType === 3" />
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="香气描述" prop="aromaDescription">
                                                    <el-input v-model="ruleForm.aromaDescription"
                                                        :disabled="this.operationType === 3" />
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="搭配" prop="recommendedPairings">
                                                    <el-input v-model="ruleForm.recommendedPairings"
                                                        :disabled="this.operationType === 3" />
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="8">
                                                <el-form-item label="合适时间" prop="suitableTime">
                                                    <el-input v-model="ruleForm.suitableTime"
                                                        :disabled="this.operationType === 3" />
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="适合人群" prop="suitableFor">
                                                    <el-input v-model="ruleForm.suitableFor"
                                                        :disabled="this.operationType === 3" />
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="购买链接" prop="purchaseLink">
                                                    <el-input v-model="ruleForm.purchaseLink"
                                                        :disabled="this.operationType === 3" />
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="8">
                                                <el-form-item label="小贴士" prop="tips">
                                                    <el-input v-model="ruleForm.tips"
                                                        :disabled="this.operationType === 3" />
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="存储说明" prop="storageInstructions">
                                                    <el-input v-model="ruleForm.storageInstructions"
                                                        :disabled="this.operationType === 3" />
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="保质期限" prop="shelfLife">
                                                    <el-input v-model="ruleForm.shelfLife"
                                                        :disabled="this.operationType === 3" />
                                                </el-form-item>
                                            </el-col>
                                        </el-row>

                                        <el-row>
                                            <el-form-item label="备注" prop="remarks">
                                                <el-input v-model="ruleForm.remarks" :disabled="this.operationType === 3" />
                                            </el-form-item>
                                        </el-row>

                                    </el-tab-pane>
                                    <el-tab-pane label="材料信息" name="ingredientsActive">
                                        <el-row>
                                            <el-col :span="2" v-if="this.operationType != 3">
                                                <!-- <el-button disabled>导入</el-button> -->
                                                <el-button @click="addIngredients(ingredientsList)" >添加</el-button>
                                                <br />
                                                统一单位:
                                                <el-cascader ref="myEmnus" v-model="ingredientsUnit" :options="options"
                                                    @change="handleChange" :show-all-levels="false" ></el-cascader>
                                            </el-col>
                                            <el-col :span="22">
                                                <el-table :data="ingredientsList" :header-row-height="50" height="300"
                                                    style="margin: -10px 0px;" center>
                                                    <el-table-column prop="date" label="序号" type="index" align="center" />
                                                    <el-table-column prop="name" label="名称" align="center" width="200">
                                                        <template slot-scope="scope">
                                                            <el-input v-model="scope.row.name" :disabled="scope.row.operationType === 3"
                                                                style="width:100%;hight:100%" />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="quantityDesc" label="分类描述" align="center" v-if="this.ruleForm.sourceData!='xun'" width="240">
                                                        <template slot-scope="scope">
                                                            <el-input  v-model="scope.row.quantityDesc" disabled />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="quantity" label="分量" align="center" >
                                                        <template slot-scope="scope">
                                                            <el-input type="number" v-model="scope.row.quantity" :disabled="scope.row.operationType === 3"/>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="quantityUnit" label="分量单位" align="center">
                                                        <template slot-scope="scope">
                                                            <el-cascader ref="myEmnus" v-model="scope.row.quantityUnit"
                                                                :options="options" :show-all-levels="false" :disabled="scope.row.operationType === 3"/>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="money" label="预估价格" align="center" v-if="this.ruleForm.sourceData=='xun'">
                                                        <template slot-scope="scope">
                                                            <el-input type="number" v-model="scope.row.money" :disabled="scope.row.operationType === 3"/>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="effect" label="作用" align="center">
                                                        <template slot-scope="scope">
                                                            <el-input v-model="scope.row.effect" :disabled="scope.row.operationType === 3"/>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="notes" label="注意事项">
                                                        <template slot-scope="scope">
                                                            <el-input v-model="scope.row.notes" :disabled="scope.row.operationType === 3"/>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="isMust" label="是否必要">
                                                        <template slot-scope="scope">
                                                            <el-checkbox v-model="scope.row.isMust" :true-label="1" :disabled="scope.row.operationType === 3"
                                                                :false-label="2">是</el-checkbox>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="substitute" label="可替代品">
                                                        <template slot-scope="scope">
                                                            <el-input v-model="scope.row.substitute" :disabled="scope.row.operationType === 3"/>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="substituteEffect" label="替代影响">
                                                        <template slot-scope="scope">
                                                            <el-input v-model="scope.row.substituteEffect" :disabled="scope.row.operationType === 3"/>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="city" label="操作" v-if="this.operationType != 3">
                                                        <template slot-scope="scope">
                                                            <el-button size="mini" type="text" icon="el-icon-edit"
                                                                @click.native.prevent="ingredientsDeleteRow(scope.$index, ingredientsList)" >删除</el-button>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </el-col>
                                        </el-row>


                                    </el-tab-pane>
                                    <el-tab-pane label="成分信息" name="componentsActive" disabled>
                                        <div class="ingredient-div">
                                            <el-row class="ingredient-row" v-for="(item, index) in items" :key="index">
                                                <el-col :span="10">
                                                    <span v-if="item.name != ''">{{ item.name }}</span>
                                                    <el-select v-model="ruleForm.productSource" placeholder="请选择" v-else>
                                                        <el-option v-for="item in dataList" :key="item.code"
                                                            :label="item.name" :value="item.code"></el-option>
                                                    </el-select>
                                                </el-col>
                                                <el-col :span="14">
                                                    <el-input-number v-model="ruleForm.name" size="medium"
                                                        controls-position="right" style="width: 100%;" />
                                                </el-col>
                                            </el-row>
                                        </div>
                                        <div style="margin: 4px 10%;">
                                            <el-button @click="addInfo">添加</el-button>
                                            <el-button @click="addInfo">自动分析</el-button>
                                            <el-button @click="analyzeShowButton">查看分析</el-button>
                                        </div>
                                    </el-tab-pane>
                                    <el-tab-pane label="功效信息" name="effectsActive" disabled>
                                        <el-row>
                                            <el-form-item label="滋补养生功效" prop="status">
                                                <!-- 滋补养生的重点是滋补身体，弥补营养不足，增强体质，促进健康。 -->
                                                <el-input placeholder="比如:补气,补血,益肾,健脾,养肝,润肺,安神,抗衰老" />
                                            </el-form-item>
                                        </el-row>
                                        <el-row>
                                            <el-form-item label="食疗养生功效" prop="status">
                                                <!-- 食疗养生的重点是治疗疾病，改善身体不适，预防疾病。 -->
                                                <el-input placeholder="比如:补虚益气,滋阴润燥,养胃健脾,降脂减肥,缓解压力等" />
                                            </el-form-item>
                                        </el-row>
                                        <el-row>
                                            <el-form-item label="调节身体功效" prop="status">
                                                <el-input placeholder="比如:提高身体免疫力、缓解疲劳、改善睡眠、减轻压力和焦虑、促进消化、预防疾病等" />
                                            </el-form-item>
                                        </el-row>
                                        <el-row>
                                            <el-form-item label="美容养颜功效" prop="status">
                                                <el-input placeholder="比如:保持皮肤弹性和光泽,减少皱纹和色斑,促进血液循环,延缓衰老等" />
                                            </el-form-item>
                                        </el-row>
                                        <el-row>
                                            <el-form-item label="保健健身功效" prop="status">
                                                <el-input placeholder="比如:提高身体素质、协调性和运动能力;增强体力、耐力;降低体脂肪含量，塑造健康美体等" />
                                            </el-form-item>
                                        </el-row>

                                    </el-tab-pane>
                                    <el-tab-pane label="操作步骤" name="stepsActive" disabled>
                                        <el-row>
                                            <el-form-item label="材料准备" prop="name" label-position="left">
                                                <el-table :data="tableData" :header-row-height="50"
                                                    style="margin: -10px 0px;">
                                                    <el-table-column fixed prop="date" label="序号">
                                                    </el-table-column>
                                                    <el-table-column fixed prop="date" label="材料名字">
                                                    </el-table-column>
                                                    <el-table-column prop="name" label="费用">
                                                    </el-table-column>
                                                    <el-table-column prop="province" label="备注">
                                                    </el-table-column>
                                                    <el-table-column prop="province" label="数量">
                                                    </el-table-column>
                                                    <el-table-column prop="province" label="单位">
                                                    </el-table-column>
                                                    <el-table-column prop="city" label="购买链接">
                                                    </el-table-column>
                                                </el-table>
                                                <div style="margin: 4px 40%;">
                                                    <el-button type="text" @click="addRow" size="mini"
                                                        style="opacity: 0.7;">添加</el-button>
                                                </div>
                                            </el-form-item>
                                        </el-row>
                                        <el-row>
                                            <el-form-item label="操作步骤" prop="name" label-position="left">
                                                <el-table :data="tableDatas" :header-row-height="50"
                                                    style="margin: -10px 0px;">
                                                    <el-table-column fixed prop="date" label="序号">
                                                    </el-table-column>
                                                    <el-table-column fixed prop="date" label="类型">
                                                    </el-table-column>
                                                    <el-table-column prop="name" label="时间">
                                                    </el-table-column>
                                                    <el-table-column prop="province" label="描述">
                                                    </el-table-column>
                                                    <el-table-column prop="city" label="是否收藏">
                                                    </el-table-column>
                                                    <el-table-column prop="city" label="操作">
                                                    </el-table-column>
                                                </el-table>
                                                <div style="margin: 4px 40%;">
                                                    <el-button type="text" @click="addRow" size="mini"
                                                        style="opacity: 0.7;">添加</el-button>
                                                </div>
                                            </el-form-item>
                                        </el-row>
                                    </el-tab-pane>
                                    <el-tab-pane label="分享内容" name="shareActive" disabled>
                                        <el-row>
                                            <el-form-item label="分享链接" prop="name" label-position="left">
                                                <el-input v-model="ruleForm.name" style="width: 80%;" />
                                            </el-form-item>
                                        </el-row>
                                        <el-row>
                                            <el-form-item label="标题" prop="name" label-position="left">
                                                <el-input v-model="ruleForm.name" style="width: 80%;" />
                                            </el-form-item>
                                        </el-row>
                                        <el-row>
                                            <el-form-item label="内容" prop="name" label-position="left">
                                                <el-input v-model="ruleForm.name" style="width: 80%;" />
                                            </el-form-item>
                                        </el-row>
                                        <el-row>
                                            <el-form-item label="分享文案" prop="name" label-position="left">
                                                <el-input v-model="ruleForm.name" style="width: 80%;" />
                                            </el-form-item>
                                        </el-row>
                                        <el-row>
                                            <el-form-item label="是否发实物图" prop="name" label-position="left">
                                                <el-input v-model="ruleForm.name" style="width: 80%;" />
                                            </el-form-item>
                                        </el-row>
                                        <el-row>
                                            <el-button style="margin: 0px 40%;" @click="previewButton">预览</el-button>
                                        </el-row>
                                    </el-tab-pane>

                                </el-tabs>
                            </el-form-item>
                        </el-row>
                    </el-form>
                    <span slot="footer" style="margin: 0 40%;" v-if="this.operationType != 3">
                        <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
                        <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
                    </span>
                </div>
                <div class="img-container" v-if="(this.previewShow) & (this.activeName == 'fourth')"
                    style="border: 1px solid red; width: 60%;height:100%;">
                    <img :src="url" alt="头像">
                </div>
                <div class="img-container" v-if="(this.analyzeShow) & (this.activeName == 'threes')"
                    style="border: 1px solid red; width: 60%;height:100%;">
                    <img :src="url" alt="头像">
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    saveOrUpdataFoodBaseApi, selectFoodByIdApi
} from "@/api/life/life/food";
export default {
    data() {
        return {
            previewShow: false,
            analyzeShow: false,
            url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            dataList: [],
            drawer: false,
            activeName: 'infoActive',
            ruleForm: {
                subType: null,
                classification: null,
                recommendationLevel: 0
            },
            titile:"新增",
            rules: {
                category: [{ required: true, message: '请选择种类', trigger: 'submit' }],
                classification: [{ required: true, message: '请选择类别', trigger: 'submit' }],
                name: [{ required: true, message: '请选择名字', trigger: 'submit' }],
            },
            maxHeight: 400, // 设置max-height为窗口高度的一部分
            formLabelWidth: 80,
            tableData: [],
            tableDatas: [],
            uploadUrl: 'your-upload-url',
            fileList: [],
            listType: 'picture-card', // 'text' or 'picture-card'
            items: [{
                type: "1",
                name: "热量"
            },
            {
                type: "1",
                name: "蛋白质"
            },
            {
                type: "1",
                name: "碳水化合物"
            },
            {
                type: "1",
                name: "维生素"
            },
            {
                type: "1",
                name: "脂肪"
            },
            {
                type: "1",
                name: "纤维素"
            },

            {
                type: "1",
                name: "水"
            },
            {
                type: "1",
                name: "矿物质"
            },
            ],
            classificationList: [{}],
            subTypeList: [{}],
            //这些改成后端获取
            alcoholClassificationList: [
                {
                    label: "蒸馏酒",
                    value: "distilled_spirits",
                    children: [
                        {
                            value: 'spirits',
                            label: '烈性蒸馏酒'
                        },
                        {
                            value: 'liqueurs_Cordials)',
                            label: '利口酒与甜酒'
                        },


                    ]
                }, {
                    label: "酿造酒",
                    value: "fermented_beverages",
                    children: [
                        {
                            label: "果酒",
                            value: "fruit_wines",
                        },
                        {
                            label: "啤酒与麦芽饮料",
                            value: "beer_malt_beverages",
                        },
                        {
                            label: "其他发酵饮料",
                            value: "other_fermented_beverages",
                        }
                    ]
                },
                {
                    label: "调和饮品",
                    value: "mixed_drinks",
                    children: [
                        {
                            label: "经典鸡尾酒 ",
                            value: "classic_cocktails",
                        },
                        {
                            label: "现代创意鸡尾酒",
                            value: "contemporary_cocktails",
                        },
                        {
                            label: "即饮鸡尾酒",
                            value: "ready_to_drink",
                        },
                        {
                            label: "无酒精鸡尾酒",
                            value: "mocktails",
                        },
                        {
                            label: "网络调酒",
                            value: "network",
                        }
                    ]
                },
                {
                    label: "其他网站来源",
                    value: "other_drinks",
                    children: [
                        {
                            label: "未知分类",
                            value: "no_cocktails",
                        }
                    ]
                },
            ],
            ingredientsList: [],
            ingredientsUnit: null,
            options: [{
                value: '658065025308889088',
                label: '常用单位组',
                children: [{
                    value: '658065078647853056',
                    label: '个'
                }, {
                    value: '658065112143564800',
                    label: '瓶'
                },
                {
                    value: '658066013444968448',
                    label: '包'
                },
                {
                    value: '658066940587479041',
                    label: 'ml'
                },
                {
                    value: '658066940587479040',
                    label: '条'
                }]
            },],
            editSwitch: false,
            operationType: 1
        }
    },
    mounted() {
        this.maxHeight = window.innerHeight * 0.8 // 设置max-height为窗口高度的80%
    },
    methods: {
        handleClick(tab, event) {
            if (this.previewShow) {
                this.previewShow = !this.previewShow
            }
            if (this.analyzeShow) {
                this.analyzeShow = !this.analyzeShow
            }
            console.log(tab, event);
        },
        show(row, type) {
            if(row.sourceData!='xun'&row!=""){
                type=3
                this.titile="查看"
            }
            this.drawer = true;
            this.operationType = type
            if(type == 2 ){
                this.titile="编辑"
            }
            if (type == 2 || type == 3) {
                this.editSwitch = true
                this.selectFoodByIdButton(row)
            }
            console.log('-', this.operationType)
        },
        selectFoodByIdButton(row) {
            selectFoodByIdApi({ uniqueId: row.uniqueId }).then(res => {
                this.ruleForm = res.result
                if (res.result.category == 'xun.life.alcohol') {
                    this.classificationList = this.alcoholClassificationList
                }
                if (null != res.result.ingredientsEntityList) {
                    const iggredientList = res.result.ingredientsEntityList
                    iggredientList.forEach(item => {
                        //el-tree-transfer组件的第一个pid必须为0
                        item.operationType =this.operationType
                    });
                    this.ingredientsList = res.result.ingredientsEntityList
                    console.log(this.ingredientsList)
                }
                const targetObject = this.classificationList.find(item => item.value === res.result.classification);
                this.subTypeList = targetObject.children
            })
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    this.clear();
                })
                .catch(_ => { });
        },
        clear() {
            this.operationType = 1
            this.drawer = false;
            this.ingredientsList = []
            this.ingredientsUnit = null
            this.classificationList = []
            this.subTypeList = []
            this.ruleForm = {
                subType: null,
                classification: null,
                recommendationLevel: 0
            }
            this.previewShow = false;
            this.analyzeShow = false
            this.editSwitch = false
            this.$parent.initAllListButton();

        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.saveOrUpdataFoodBaseButton()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        saveOrUpdataFoodBaseButton() {
            this.ruleForm.ingredientsEntityList = this.ingredientsList
            saveOrUpdataFoodBaseApi(this.ruleForm).then(res => {
                this.clear();
            })
        },

        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        handleSuccess(response, file, fileList) {
            console.log(response, file, fileList);
        },

        addRow() { },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        addInfo() {
            var data = {
                name: ''
            }
            this.items.push(data)
        },
        previewButton() {
            this.previewShow = !this.previewShow
        },
        analyzeShowButton() {
            this.analyzeShow = !this.analyzeShow
        },
        handleRadioChange(value) {
            this.ruleForm.classification = ''
            this.ruleForm.subType = ''
            this.classificationList = []
            this.subTypeList = []
            if (value == 'xun.life.alcohol') {
                this.classificationList = this.alcoholClassificationList
            }

        },
        AddSelectDept(value) {
            const targetObject = this.classificationList.find(item => item.value === value);
            this.subTypeList = targetObject.children
            this.ruleForm.subType = ''
        },
        addIngredients(row) {
            var data = {
                quantityUnit: this.ingredientsUnit
            }
            row.push(data)
        },
        ingredientsDeleteRow(index, rows) {
            rows.splice(index, 1);
        },
        handleChange(value, type) {
            const lastSelectedValue = value[value.length - 1];
            this.ingredientsUnit = lastSelectedValue
            this.ingredientsList.forEach(item => {
                // 如果对象中有value属性，直接修改其值为2
                item.quantityUnit = lastSelectedValue
            });
        },
    }

}
</script>

<style  lang="less" scoped>
.demo-ruleForm {
    overflow-y: auto;
    max-height: 90%; // 设置最大高度
}

.demo-drawer__footer {
    display: flex;
    justify-content: flex-end;
    padding: 0px 10px;
    border-top: 1px solid #ebeef5;
}



.ingredient-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.ingredient-row {
    width: 50%;
    margin-bottom: 20px;
    text-align: center;
    padding: 0px 20px;
    box-sizing: border-box;
}

.form-container {
    display: flex;
    align-items: center;
}

.img-container {
    margin-left: 6px;
    order: 2;
    align-self: stretch;
    display: flex;
    justify-content: flex-end;
}

.form-item-container {
    flex: 1;
    order: 1;
}


.img-container img {
    width: 80%;
    object-fit: contain;
}</style>